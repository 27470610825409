$(document).ready(function() {


    var owlSlider = $('.owl-slider');
    owlSlider.owlCarousel({
        loop: false,
        margin: 0,
        nav: false,
        dots: true,
        items: 1,
        autoplay:true
    });

  var owlPhotos = $('.owl-photogalery');
  owlPhotos.owlCarousel({
      loop: false,
      margin: 25,
      nav: true,
      dots: true,
      stagePadding:15,
      navText: ['<i class="icon icon-arrow-left"><i>','<i class="icon icon-arrow-right"><i>'],
      responsive : {
          0 : {
              items: 1
          },
          768 : {
              items: 2
          },
          880 : {
              items: 3
          },
          1100 : {
              items: 4
          }
      },
      autoplay:false
  });
  $('#product-detail .text .more').click(function(){
      $(this).addClass('hide');
     $('#product-detail .text ul').addClass('active');
  });
  $('#product-detail a.close').click(function(){
      $('#product-detail').removeClass('active');
  });

  $('.cat-gift').click(function(){
      $('.cat-gift').addClass('disabled');
      $(this).removeClass('disabled');
      $('.c-gift').addClass('d-none');
      $('.c-gift[data-val="'+$(this).attr('data-cat')+'"]').removeClass('d-none');
  });



  var didScroll;

 $(window).scroll(function(event){
       didScroll = true;
 });

 setInterval(function() {
      if (didScroll) {
          hasScrolled();
          didScroll = false;
      }
  }, 500);


  function hasScrolled() {
      if($(this).scrollTop() > 0){
          $('#head').addClass('scrolled');
      }else{
          $('#head').removeClass('scrolled');
      }
  }
});
